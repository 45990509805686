

































































































































































































































































































































































































































































































































.order-form {
  padding: 30px 40px;
}
.order-details {
  padding: 30px;
  background: #ffffe9;
  ul {
    padding: 0;
    margin-top: 30px;
    list-style: none;
    li {
      position: relative;
      padding-bottom: 15px;
      padding-left: 25px;
      .feather {
        position: absolute;
        top: 2px;
        left: 0;
        color: #0ca90c;
      }
    }
  }
}
.order-page {
  .box-upgrade {
    margin-bottom: 30px;
    .btn {
      max-width: 100%;
      padding: 15px 100px;
    }
  }
  .secure-images img {
    width: 300px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
